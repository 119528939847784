.sec-header {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    font-size: 2rem;
}

.sec-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center; /* Center items if space permits */
}

.sec-card {
    flex: 1 1 calc(48% - 1rem); /* Adjust width to cover spaces automatically */
    max-width: calc(48% - 1rem); /* Ensure cards don’t exceed the row width */
    height: 50vh;
    border-radius: 1rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Slight border */
    padding: 1rem; /* Add padding inside the card */
    margin: 0.5rem; /* Add margin to avoid overlap */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.sec-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    z-index: 1; /* Ensure hovered card is above others */
}

.sec-card img {
    width: 100%;
    height: 43vh;
    border-radius: 1rem;
    object-fit: cover; /* Maintain aspect ratio and fill the container */
}

.section {
    padding-top: 1rem;
    padding-left: 2rem; /* Updated padding */
    padding-right: 2rem; /* Updated padding */
    padding-bottom: 4rem;
}

@media (max-width: 768px) {
    .sec-card {
        flex: 1 1 calc(50% - 1rem); /* 2 cards per row for small screens */
        max-width: calc(50% - 1rem);
    }
}

@media (max-width: 480px) {
    .sec-card {
        flex: 1 1 100%; /* 1 card per row for extra small screens */
        max-width: 100%;
    }
}
