.hero-feature{
    background-color:white;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.206);
    display:flex;
    flex-direction:column;
    gap:1rem;
    align-items:center;
    width:20vw;
    height:40vh;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.hero-features{
    display:flex;
    gap:2rem;
    flex-direction:row;
    flex-wrap:wrap;
    padding:5rem;


   
}

.hero-feature .text{
    text-align:center;
}

.hero-feature img {
   top: 0;
   left: 0;
   width: 100%;
   height: 80%;
   object-fit: cover;
   border-radius: 1rem;
 }

.mInputs{
  width: 100%;
  padding-bottom:10px;
  border: 0.10px solid black;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
  transition: all 0.5s ease-in-out;

}







.wwd-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap:2rem;

}

.wwd-column{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap:2rem;

}

.title{
    font-size: 2.6rem;
    font-weight: bold;
    color: #221577 ;
    text-align:center;
    line-height: 3.5rem;
    text-transform: capitalize;
    text-decoration: underline;

}

.sec-title{
    font-size: 2rem;
    font-weight: 600;
    text-align:center;
    line-height: 3rem;
    text-transform: capitalize;

}


.card{
    width: 30vw;
    height:auto;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.206);
    display:flex;
    flex-direction:column;
    gap:1rem;
    align-items:center;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.Bigcard{
    width: 95%;
    height:auto;
    box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.206);
    display:flex;
    flex-direction:column;
    gap:1rem;
    border-radius: 1rem;
    padding:2rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}



.pageTitle{
    
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 5rem;
    padding-top: 1rem;


}


.e-grid .e-rowcell {
    white-space: normal !important;
    word-wrap: break-word;
  }

  .custom-grid-cell {
    white-space: normal !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  



@media(max-width:1250px){
    .wwd-row{
        flex-direction: column;
    }

    .card{
        width: 100%;
        height:auto;
    }

}



@media(max-width:990px){
    .hero-feature{
        width:50vw;
    }

    .hero-features{
        padding:1
    }

    .card{
        width: 100%;
        height:auto;
    }

    .wwd-row{
        flex-direction: column;
    }
 

}





@media(max-width:850px){
    .hero-feature{
        width:80vw;
    }

    .hero-features{
        padding:1
    }

    .wwd-row{
        flex-direction: column;
    }

    .card{
        width: 100%;
        height:auto;
    }

    

}

@media (max-width:768px){

    .hero-features{
        padding:1
    }

    .wwd-row{
        flex-direction: column;
    }
    .card{
        width: 100%;
        height:auto;
    }

    .pageTitle{
    
        padding: 1rem;
    
    
    }
    


    

}





















































































